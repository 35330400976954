import React from "react"
import "./NewsCard.scss"
import { Link } from "gatsby"

interface NewsCardProps {
  newsDate: string
  newsSource: string
  newsName: string
  newsLinkedIn: string
  newsTwitter: string
  newsLink: string
  newsCat: string
  newsVisibility: string
  customTextLink: string
  type: "post" | "link"
}

const NewsCard = ({
  newsDate,
  newsSource,
  newsName,
  newsLinkedIn,
  newsTwitter,
  newsLink,
  newsCat,
  newsVisibility,
  type,
  customTextLink,
}: NewsCardProps) => {
  return (
    <VariableLink {...{ type, newsLink, newsCat, newsVisibility }}>
      <div className="News__card__top">
        <p className="News__date">{newsDate}</p>
        <p className="News__source">{newsSource}</p>
        <h3 className="News__name">{newsName}</h3>
      </div>

      <div className="News__card__bottom">
        <div className="News__socials">
          <a href={`${newsLinkedIn}`} className="News__linkedIn"></a>
          <a href={`${newsTwitter}`} className="News__twitter"></a>
        </div>
        <div className="News__read">
        {(!customTextLink) && (
          <p>{type === "link" ? "Read Article" : "Read Press Release"}</p>
        )}
        {(customTextLink) && (
          <p>{customTextLink}</p>
        )}
          <div className="News__read__arrow"></div>
        </div>
      </div>
    </VariableLink>
  )
}

export default NewsCard

const VariableLink = ({
  children,
  type,
  newsLink,
  newsCat,
  newsVisibility,
  customTextLink,
}: any) => {
  if (type === "link") {
    return (
      <a
        href={newsLink}
        target="_blank"
        className={`${newsCat} ${newsVisibility} w1-3 News__card`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        to={`/blog/${newsLink}`}
        className={`${newsCat} ${newsVisibility} w1-3 News__card`}
      >
        {children}
      </Link>
    )
  }
}
