import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout/Layout"
import PostSuggest from "../../components/PostSuggest/PostSuggest"
import Section from "../../components/Section/Section"
import Seo from "../../components/Seo/Seo"
import "../../styles/blogpost.scss"
import NewsLinkedIn from "../../svg/ico_linkedBlue.svg"
import NewsTwitter from "../../svg/ico_twitterBlue.svg"

const Blogpost = ({ data }: any) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout hideForm>
      <Seo title={`${frontmatter.title} | Resistant.AI`} />

      <Section sectionClass="Blogpost">
        <div className="Blogpost__body">
          <div className="w-wrap">
            <h2 className="Blogpost__title">{frontmatter.title}</h2>
          </div>

          <div className="w-wrap Blogpost__subtitle">
            <div className="Blogpost__date">{frontmatter.date}</div>
            <div className="Blogpost__source">{frontmatter.source}</div>
          </div>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        {/* <BlogpostSocials /> */}

        <div className="Blogpost__share">
          <a
            target="blank"
            href="https://www.linkedin.com/company/resistant-ai/"
          >
            <img src={NewsLinkedIn} />
          </a>
          <a target="blank" href="https://www.twitter.com/ResistantAI">
            <img src={NewsTwitter} />
          </a>
        </div>
      </Section>

      <PostSuggest parentPostTitle={frontmatter.title} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        newsSource
      }
    }
  }
`

export default Blogpost
