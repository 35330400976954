import { graphql, StaticQuery } from "gatsby"
import React from "react"
import NewsCard from "../../components/NewsCard/NewsCard"
import Section from "../../components/Section/Section"
import "./PostSuggest.scss"

interface PostSuggestProps {
  parentPostTitle: string
}

const PostSuggest = ({ parentPostTitle }: PostSuggestProps) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
          ) {
            nodes {
              excerpt
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                type
                newsSource
                link
              }
            }
          }
        }
      `}
      render={data => (
        <Section sectionClass="PostSuggest">
          <div className="w-wrap">
            <div className="MainSection__caption">You might also like</div>
          </div>

          <div className="w-wrap">
            {(data.allMarkdownRemark.nodes as any[])
              ?.sort((a, b) => a.frontmatter.date - b.frontmatter.date)
              ?.filter(
                (post: any) =>
                  post.frontmatter.title !== parentPostTitle &&
                  post.frontmatter.newsSource === "Press Release"
              )
              .slice(0, 3)
              .map((post: any, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    <NewsCard
                      newsDate={post.frontmatter.date}
                      newsSource={post.frontmatter.newsSource}
                      newsName={post.frontmatter.title}
                      newsLink={post.frontmatter.link}
                      type={post.frontmatter.type}
                      newsLinkedIn="https://www.idnes.cz"
                      newsTwitter="https://www.idnes.cz"
                      newsCat="Articles"
                      newsVisibility={""}
                    ></NewsCard>
                  </React.Fragment>
                )
              })}
          </div>
        </Section>
      )}
    ></StaticQuery>
  )
}

export default PostSuggest
